import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import { Container, Alert } from 'react-bootstrap';

const RegistrationSuccess = () => {
  const [type, setType] = useState('registration'); // or 'sponsorship'

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get('session_id');
    const paymentType = queryParams.get('type'); // Add this to your success_url

    if (!sessionId) {
      navigate('/2025-golf-tournament');
    }

    if (paymentType === 'sponsorship') {
      setType('sponsorship');
    }
  }, []);

  return (
    <Layout>
      <Container>
        <Alert variant="success" className="mt-5">
          <Alert.Heading>
            {type === 'sponsorship' ? 'Sponsorship' : 'Registration'} Successful!
          </Alert.Heading>
          <p>
            {type === 'sponsorship' 
              ? 'Thank you for sponsoring the USS IDAHO 2025 Golf Tournament.'
              : 'Thank you for registering for the USS IDAHO 2025 Golf Tournament.'}
            You will receive a confirmation email shortly with additional details.
          </p>
          <hr />
          <p className="mb-0">
            If you have any questions, please contact us at <a href="mailto:scott.rumph@ussidahocommittee.org">scott.rumph@ussidahocommittee.org</a>.
          </p>
          <a href="/2025-golf-tournament">Back to Golf Tournament Registration</a>
        </Alert>
      </Container>
    </Layout>
  );
};

export default RegistrationSuccess; 